@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');

* {
	box-sizing: border-box;
}

@font-face {
	font-family: 'Rubik';
	/* src: url('../assets/fonts/Rubik.ttf'); */
}

@layer components {
	.nav_item {
		@apply ml-8 text-gray-900 hover:text-sky-600 hover:underline underline-offset-1;
	}

	.link {
		@apply focus:outline-none;
	}
	.form_field {
		@apply mb-3 mt-1 w-full rounded-lg border-gray-300 shadow-sm focus:outline-none;
	}
}

.no_outline {
	outline: none;
}

#github {
	clip-path: polygon(0% 0%, 75% 0%, 81% 51%, 75% 100%, 0% 100%);
}
